import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, useMediaQuery } from '@mui/material'

import { CONSTANTS } from '@epilogue/common'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import DocumentPageSkeleton from '../../../../../../../../common/assets/images/document-page-skeleton-purple-background.svg'
import ManualPrint from '../../../../../../../../common/assets/images/manual-print-purple-background.svg'
import PrintRequest from '../../../../../../../../common/assets/images/print-request-purple-background.svg'
import { ReactComponent as PurpleStar } from '../../../../../../../../common/assets/images/purple-star.svg'
import SignWill from '../../../../../../../../common/assets/images/sign-will-purple-background.svg'
import WillDocument from '../../../../../../../../common/assets/images/will-document-card-purple-background.svg'
import Button from '../../../../../../../../common/components/atoms/Button'
import Checkbox from '../../../../../../../../common/components/atoms/Checkbox'
import Heading from '../../../../../../../../common/components/atoms/Heading'
import Modal from '../../../../../../../../common/components/atoms/Modal'
import Text from '../../../../../../../../common/components/atoms/Text'
import StepDots from '../../../../../../../../common/components/molecules/StepDots'
import commonActions from '../../../../../../../../common/ducks/commonActions'
import usePreloadImages from '../../../../../../../../common/hooks/usePreloadImages'
import useUpdateToggles from '../../../../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../../../../common/lib/amplitude'
import {
  themeColors,
  themeColorTypes,
  themeColorVariants,
} from '../../../../../../../../common/styles/muiTheme'
import useEmailDocuments from '../../../hooks/useEmailDocuments'
import PrimaryButton from './components/PrimaryButton'
import SecondaryButton from './components/SecondaryButton'

const animateDistance = 350
const variants = {
  start: (direction: number) => ({
    opacity: 0,
    x: direction > 0 ? animateDistance : -animateDistance,
  }),
  enter: {
    x: 0,
    zIndex: 1,
    opacity: 1,
  },
  leave: (direction: number) => ({
    zIndex: 0,
    opacity: 0,
    x: direction < 0 ? animateDistance : -animateDistance,
  }),
}

const preloadedImages = [
  WillDocument,
  PrintRequest,
  ManualPrint,
  SignWill,
  DocumentPageSkeleton,
]

interface Buttons {
  Primary: React.FC
  Secondary?: React.FC
}

interface Step {
  id: number
  img: string
  label: string
  body: string[]
  buttons: Buttons
  interceptCheckboxLabel?: string
}

interface Steps {
  t: any
  token: string
  userId: string
  answerStore: any
  onClose: () => void
  handleNext: () => void
  questionnaireId: string
  hasPrintCredit: boolean
  handleUpdateToggles: any
  checkboxIsInvalid: boolean
  interceptCheckboxesChecked: number[]
  setCheckboxIsInvalid: (boolUpdate: boolean) => void
  dispatchUpdateToast: (toastProperties: any) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const steps = ({
  t,
  token,
  userId,
  onClose,
  handleNext,
  answerStore,
  hasPrintCredit,
  questionnaireId,
  checkboxIsInvalid,
  dispatchUpdateToast,
  handleUpdateToggles,
  setCheckboxIsInvalid,
  interceptCheckboxesChecked,
  dispatchSetGlobalErrorMessage,
}: Steps): Step[] => {
  const { product } = answerStore
  return [
    // Step 1
    {
      id: 1731433557,
      label: t('components:modal.DocumentsGuideModal.steps.1.label'),
      body:
        product === 'willPoa'
          ? t('components:modal.DocumentsGuideModal.steps.1.body.willPoa', {
              returnObjects: true,
              email: answerStore.email,
            })
          : t('components:modal.DocumentsGuideModal.steps.1.body.willOnly', {
              returnObjects: true,
              email: answerStore.email,
            }),
      img: WillDocument,
      buttons: {
        Primary: () => {
          const { EmailStatusTypes, status, handleSubmit } = useEmailDocuments({
            userId,
            answerStore,
            dispatchSetGlobalErrorMessage,
          })

          useEffect(() => {
            if (status === EmailStatusTypes.SUCCESS) {
              dispatchUpdateToast({
                type: CONSTANTS.toastTypes.SUCCESS,
                hideDuration: 4000,
                label: t(
                  'components:modal.DocumentsGuideModal.steps.1.toast.label',
                ),
              })

              handleNext()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [status])

          return (
            <PrimaryButton
              onClick={handleSubmit}
              working={status === EmailStatusTypes.WORKING}
              label={t(
                'components:modal.DocumentsGuideModal.steps.1.buttons.primary.label',
              )}
            />
          )
        },
        Secondary: () => (
          <SecondaryButton
            onClick={handleNext}
            label={t(
              'components:modal.DocumentsGuideModal.steps.1.buttons.secondary.label',
            )}
          />
        ),
      },
    },

    // Step 2
    {
      id: 1731433573,
      label: t('components:modal.DocumentsGuideModal.steps.2.label'),
      body: hasPrintCredit
        ? product === 'willPoa'
          ? t(
              'components:modal.DocumentsGuideModal.steps.2.body.hasPrintCredit.willPoa',
              { returnObjects: true },
            )
          : t(
              'components:modal.DocumentsGuideModal.steps.2.body.hasPrintCredit.willOnly',
              { returnObjects: true },
            )
        : product === 'willPoa'
          ? t(
              'components:modal.DocumentsGuideModal.steps.2.body.noPrintCredit.willPoa',
              { returnObjects: true },
            )
          : t(
              'components:modal.DocumentsGuideModal.steps.2.body.noPrintCredit.willOnly',
              { returnObjects: true },
            ),
      img: hasPrintCredit ? PrintRequest : ManualPrint,
      buttons: {
        Primary: () => (
          <PrimaryButton onClick={handleNext} label={t('common:next')} />
        ),
      },
    },

    // Step 3
    {
      id: 1731433580,
      label: t('components:modal.DocumentsGuideModal.steps.3.label'),
      body:
        product === 'willPoa'
          ? t('components:modal.DocumentsGuideModal.steps.3.body.willPoa', {
              returnObjects: true,
            })
          : t('components:modal.DocumentsGuideModal.steps.3.body.willOnly', {
              returnObjects: true,
            }),
      interceptCheckboxLabel: t('common:iUnderstand'),
      img: SignWill,
      buttons: {
        Primary: () => (
          <PrimaryButton
            label={t('common:next')}
            disabled={!interceptCheckboxesChecked.includes(1731433580)}
            onClick={() => {
              if (!checkboxIsInvalid) {
                handleNext()
              }
            }}
            onDisabledClick={() => {
              if (!checkboxIsInvalid) {
                setCheckboxIsInvalid(true)
              }
            }}
          />
        ),
      },
    },

    // Step 4
    {
      id: 1731433585,
      label: t('components:modal.DocumentsGuideModal.steps.4.label'),
      body:
        product === 'willPoa'
          ? t('components:modal.DocumentsGuideModal.steps.4.body.willPoa', {
              returnObjects: true,
            })
          : t('components:modal.DocumentsGuideModal.steps.4.body.willOnly', {
              returnObjects: true,
            }),
      img: DocumentPageSkeleton,
      buttons: {
        Primary: () => (
          <PrimaryButton
            onClick={() => {
              amplitude.sendEvent('ClickedAcceptDocumentsGuideModal')
              onClose()
              handleUpdateToggles({
                token,
                questionnaireId,
                toggleOn: [
                  CONSTANTS.toggleTypes.HAS_ACCEPTED_STEPPED_DOCUMENTS_MODAL,
                ],
              })
            }}
            label={t('common:gotIt')}
            forwardArrow={false}
          />
        ),
      },
    },
  ]
}

interface Props {
  token: string
  show: boolean
  userId: string
  answerStore: any
  onClose: () => void
  hasPrintCredit: boolean
  questionnaireId: string
  dispatchUpdateToast: (toastProperties: any) => void
  dispatchSetGlobalErrorMessage: (msg: string) => void
}

const DocumentsGuideModal = ({
  show,
  token,
  userId,
  onClose,
  answerStore,
  hasPrintCredit,
  questionnaireId,
  dispatchUpdateToast,
  dispatchSetGlobalErrorMessage,
}: Props) => {
  const { t } = useTranslation()

  const { mutate: handleUpdateToggles } = useUpdateToggles()

  usePreloadImages({ imageUrls: preloadedImages })

  const [direction, setDirection] = useState(1)
  const [activeStep, setActiveStep] = useState(0)
  const [checkboxIsInvalid, setCheckboxIsInvalid] = useState(false)
  const [interceptCheckboxesChecked, setInterceptCheckboxesChecked] = useState<
    number[]
  >([])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setDirection(1)
    amplitude.sendEvent('ClickedDocumentsGuideModalForwardButton')
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    amplitude.sendEvent('ClickedDocumentsGuideModalBackButton')
    setDirection(-1)
    if (checkboxIsInvalid) {
      setCheckboxIsInvalid(false)
    }
  }

  const resolvedSteps = steps({
    t,
    token,
    userId,
    onClose,
    handleNext,
    answerStore,
    hasPrintCredit,
    questionnaireId,
    checkboxIsInvalid,
    handleUpdateToggles,
    dispatchUpdateToast,
    setCheckboxIsInvalid,
    interceptCheckboxesChecked,
    dispatchSetGlobalErrorMessage,
  })

  const currentStep = resolvedSteps[activeStep]
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('lg'))

  useEffect(() => {
    if (show)
      amplitude.sendEvent('ViewedDocumentsGuideModal', {
        hasPrintCredit,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal
      fullBleed
      show={show}
      preventClose
      maxWidth="md"
      onClose={onClose}
      data-testid="documents-guide-modal"
    >
      <Box
        sx={{
          width: isMobile ? '100%' : (theme) => theme.breakpoints.values.md,
        }}
      >
        <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
          <Box
            sx={{
              width: '100%',
              height: isMobile ? '100%' : '330px',
              backgroundColor:
                themeColors[themeColorTypes.ACCENT_2][
                  themeColorVariants.LIGHTEST
                ],
            }}
          >
            <motion.img
              key={activeStep}
              src={currentStep.img}
              custom={direction}
              variants={variants}
              initial="start"
              animate="enter"
              exit="leave"
              transition={{
                x: { type: 'spring', stiffness: 280, damping: 30 },
                opacity: { duration: 0.3 },
              }}
            />
          </Box>
        </AnimatePresence>

        {/* White background area */}
        <Box
          sx={{
            px: '2rem',
            py: '1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isMobile ? 'auto' : '315px',
          }}
        >
          <Box>
            <Box
              sx={{
                mb: '1rem',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ mb: '0.9rem' }}>
                <StepDots
                  preventDotClick
                  steps={resolvedSteps.length}
                  activeStep={activeStep}
                />
              </Box>

              <Heading align="center" variant={isMobile ? 'h4' : 'h3'}>
                <PurpleStar
                  style={{
                    width: '19px',
                    bottom: '3px',
                    display: 'inline',
                    position: 'relative',
                    marginRight: '0.4rem',
                  }}
                />
                {currentStep.label}
              </Heading>
            </Box>

            <Box sx={{ maxWidth: '445px', mx: 'auto' }}>
              {React.Children.toArray(
                currentStep.body.map((text) => (
                  <Text
                    size="sm"
                    align="center"
                    sx={{ mt: '0.4rem', lineHeight: '1.5' }}
                  >
                    {text}
                  </Text>
                )),
              )}
            </Box>
          </Box>

          {currentStep.interceptCheckboxLabel && (
            <Box
              sx={{
                mt: '1rem',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',
              }}
            >
              <Checkbox
                isInvalid={checkboxIsInvalid}
                checked={interceptCheckboxesChecked.includes(currentStep.id)}
                label={currentStep.interceptCheckboxLabel}
                onChange={(checkValue: boolean) => {
                  amplitude.sendEvent('CheckedSignReminder')

                  if (checkboxIsInvalid) {
                    setCheckboxIsInvalid(false)
                  }

                  if (checkValue) {
                    setInterceptCheckboxesChecked([
                      ...interceptCheckboxesChecked,
                      currentStep.id,
                    ])
                  } else {
                    setInterceptCheckboxesChecked(
                      interceptCheckboxesChecked.filter(
                        (step) => step !== currentStep.id,
                      ),
                    )
                  }
                }}
              />

              {checkboxIsInvalid && (
                <Text
                  sx={{
                    right: '50%',
                    width: '100%',
                    bottom: '-11px',
                    textAlign: 'center',
                    position: 'absolute',
                    transform: 'translateX(50%)',
                  }}
                  size="xxs"
                  color={themeColorTypes.RED}
                >
                  *{t('validation:validation.checkBoxAbove')}
                </Text>
              )}
            </Box>
          )}

          <Box
            sx={{
              mt: '2.2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              size="xxs"
              label={t('common:back')}
              onClick={handleBack}
              iconFontSize="0.9rem"
              color={themeColorTypes.GREY}
              colorVariant={themeColorVariants.DARK}
              data-testid="documents-guide-modal-back-button"
              startIcon={<ArrowBackIcon sx={{ mr: '-0.2rem' }} />}
              sx={{ visibility: activeStep === 0 ? 'hidden' : 'visible' }}
            />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
              }}
            >
              <currentStep.buttons.Primary />

              {currentStep.buttons?.Secondary && (
                <Box sx={{ mt: '0.6rem' }}>
                  <currentStep.buttons.Secondary />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  dispatchUpdateToast: (toastProperties: any) =>
    dispatch(commonActions.updateToast(toastProperties)),
})

export default connect(null, mapDispatchToProps)(DocumentsGuideModal)
