import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Box, Grid, useMediaQuery } from '@mui/material'

import { CONSTANTS, Referrer, utils } from '@epilogue/common'
import { differenceInHours, isAfter, parseISO } from 'date-fns'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import Accordion from '../../../../../common/components/atoms/Accordion'
import Heading from '../../../../../common/components/atoms/Heading'
import LoadingSpinner from '../../../../../common/components/atoms/LoadingSpinner'
import Text from '../../../../../common/components/atoms/Text'
import EverythingButton from '../../../../../common/components/molecules/EverythingButton'
import commonActions from '../../../../../common/ducks/commonActions'
import useFetchToggles from '../../../../../common/hooks/useFetchToggles'
import useUpdateToggles from '../../../../../common/hooks/useUpdateToggles'
import amplitude from '../../../../../common/lib/amplitude'
import event from '../../../../../common/lib/event'
import queryString from '../../../../../common/lib/queryString'
import sessionStore from '../../../../../common/lib/sessionStore'
import {
  themeColorTypes,
  themeColorVariants,
} from '../../../../../common/styles/muiTheme'
import { witnessingProvinces } from '../../../../../common/utils/provinces'
import questionnaireSelectors from '../../../../Questionnaire/ducks/questionnaire/questionnaireSelectors'
import addOnsActions from '../../../ducks/addOns/addOnsActions'
import addOnsSelectors from '../../../ducks/addOns/addOnsSelectors'
import bonusDocsSelectors from '../../../ducks/bonusDocs/bonusDocsSelectors'
import dashboardSelectors from '../../../ducks/dashboard/dashboardSelectors'
import documentsSelectors from '../../../ducks/documents/documentsSelectors'
import paymentsSelectors from '../../../ducks/payments/paymentsSelectors'
import hasCoreDocument from '../../../utils/hasCoreDocument'
import hasCoreProduct from '../../../utils/hasCoreProduct'
import EmailDocumentsButton from './components/buttons/EmailDocumentsButton'
import PrintDocumentsButton from './components/buttons/PrintDocumentsButton'
import WitnessingButton from './components/buttons/WitnessingButton'
import DocumentCards from './components/DocumentCards'
import DocumentsGuideModal from './components/modals/DocumentsGuideModal'
import FollowUpSigningModal from './components/modals/FollowUpSigningModal'
import SignReminderModal from './components/modals/SignReminderModal'
import StaleDocumentsModal from './components/modals/StaleDocumentsModal'
import documentCardsInfo from './data/documentCardsInfo'
import isTriggered from './utils/isTriggered'

const ANIMATION_STAGGER = 0.075

const containerAnimationVariants = (delay: number) => ({
  visible: {
    transition: {
      delayChildren: delay,
      staggerChildren: ANIMATION_STAGGER,
    },
  },
})

interface Props {
  token: string
  userId: string
  payments: any[]
  paidAddOns: any[]
  // TS-TODO: fix when document is typed
  documents: any[]
  referrer: Referrer
  questionnaireId: string
  questionnaireValid: boolean
  fetchDocumentsWorking: boolean
  dispatchFetchAddOns: () => void
  generateDocumentsWorking: boolean
  answerStore: { [key: string]: any }
  answerStoreUpdatedSinceGenerate: boolean
  bonusDocsAnswerStore: { [key: string]: any }
  dispatchSetGlobalErrorMessage: (msg: string) => void

  noticeConnectCode?: string
  customerReferralCode?: string
}

const Documents = ({
  token,
  userId,
  payments,
  referrer,
  paidAddOns,
  answerStore,
  questionnaireId,
  questionnaireValid,
  dispatchFetchAddOns,
  bonusDocsAnswerStore,
  fetchDocumentsWorking,
  generateDocumentsWorking,
  noticeConnectCode = undefined,
  documents: generatedDocuments,
  dispatchSetGlobalErrorMessage,
  answerStoreUpdatedSinceGenerate,
  customerReferralCode = undefined,
}: Props) => {
  const location = useLocation()
  const { t } = useTranslation()

  const { autoOpenModalType } = queryString.parse(location.search)

  const hasPrintCredit = utils.containsAddOn({
    addOns: paidAddOns,
    addOnType: CONSTANTS.addOnTypes.PRINTING.type,
  })
  const hasWitnessingCredit = utils.containsAddOn({
    addOns: paidAddOns,
    addOnCategory: CONSTANTS.addOnTypes.VIRTUAL_WITNESSING_WILL.category,
  })

  const hasCoreProductPayment = hasCoreProduct(payments)
  const hasGeneratedCoreDocument = hasCoreDocument(generatedDocuments)
  const hasPaidCoreDocument = hasCoreProductPayment && hasGeneratedCoreDocument

  const latestCoreDocumentGenerationDate = _get(
    generatedDocuments.find((doc) => doc.docType === CONSTANTS.docTypes.WILL),
    'created',
  )
  // Has core document generation happened within the last 24 hours?
  const coreDocGenerationHappenedToday =
    differenceInHours(new Date(), parseISO(latestCoreDocumentGenerationDate)) <
    24

  const { data: toggles, isSuccess: toggleFetchSuccess } = useFetchToggles({
    token,
    questionnaireId,
  })
  const { mutate: handleUpdateToggles } = useUpdateToggles()

  //
  // Stepped Documents Modal Controls STARTS
  //
  const hasAcceptedSteppedDocumentsModal = _get(
    toggles,
    CONSTANTS.toggleTypes.HAS_ACCEPTED_STEPPED_DOCUMENTS_MODAL,
  )
  const [forceHideSteppedDocumentsModal, setForceHideSteppedDocumentsModal] =
    useState<boolean>(false)

  const showSteppedModal =
    hasPaidCoreDocument &&
    !forceHideSteppedDocumentsModal &&
    !hasAcceptedSteppedDocumentsModal

  //
  // Stale Documents Modal Controls STARTS
  //
  const [forceHideStaleDocumentsModal, setForceHideStaleDocumentsModal] =
    useState<boolean>(false)

  const showStaleDocumentsModal =
    !showSteppedModal &&
    hasGeneratedCoreDocument &&
    !forceHideStaleDocumentsModal &&
    answerStoreUpdatedSinceGenerate

  //
  // Sign Reminder Modal Controls STARTS
  //
  const hasAcceptedSignReminder = _get(
    toggles,
    CONSTANTS.toggleTypes.HAS_ACCEPTED_SIGN_REMINDER,
  )
  const [forceHideSignReminderModal, setForceHideSignReminderModal] =
    useState<boolean>(false)

  const showSignReminderModal =
    !showSteppedModal &&
    hasPaidCoreDocument &&
    !showStaleDocumentsModal &&
    !forceHideSignReminderModal &&
    !forceHideStaleDocumentsModal &&
    // Only show if core document generation happened less than 24 hours ago
    coreDocGenerationHappenedToday &&
    (!hasAcceptedSignReminder ||
      // If the user has generated core docs since hasAcceptedSignReminder toggle was set
      isAfter(
        parseISO(latestCoreDocumentGenerationDate),
        parseISO(hasAcceptedSignReminder),
      )) &&
    !sessionStore.get(CONSTANTS.sessionStorageKeys.PREVENT_SIGNING_MODALS)

  //
  // Follow-up Signing Modal Controls STARTS
  //
  const hasSignedCoreDocuments = _get(
    toggles,
    CONSTANTS.toggleTypes.HAS_SIGNED_CORE_DOCUMENTS,
  )
  const [forceHideFollowUpSigningModal, setForceHideFollowUpSigningModal] =
    useState<boolean>(false)

  const showFollowUpSigningModal =
    !showSteppedModal &&
    hasPaidCoreDocument &&
    !showSignReminderModal &&
    !showStaleDocumentsModal &&
    !forceHideStaleDocumentsModal &&
    !forceHideFollowUpSigningModal &&
    // Only show if core document generation happened more than 24 hours ago
    !coreDocGenerationHappenedToday &&
    (!hasSignedCoreDocuments ||
      // If the user has generated core docs since hasSignedCoreDocuments toggle was set
      isAfter(
        parseISO(latestCoreDocumentGenerationDate),
        parseISO(hasSignedCoreDocuments),
      )) &&
    !sessionStore.get(CONSTANTS.sessionStorageKeys.PREVENT_SIGNING_MODALS)

  const triggerObj = { answerStore, bonusDocsAnswerStore, referrer }

  const sections = documentCardsInfo({
    token,
    userId,
    toggles,
    // TAG: TD setup
    referrer,
    answerStore,
    questionnaireId,
    noticeConnectCode,
    generatedDocuments,
    customerReferralCode,
    hasCoreProductPayment,
    answerStoreUpdatedSinceGenerate,
    autoOpenModalType: autoOpenModalType as string,
  })

  const sectionsWithTriggeredCards = sections
    .map((section) => ({
      ...section,
      /*
       * We remove untriggered cards here to ensure cascarding-cards
       * animation doesn't calculate based on untriggred cards
       */
      cards: section.cards.filter(
        ({ trigger }) => !trigger || isTriggered(triggerObj)(trigger),
      ),
    }))
    // Filter out sections without cards
    .filter((section) => !_isEmpty(section.cards))

  const hideEverythingButton =
    questionnaireValid &&
    hasPaidCoreDocument &&
    !answerStoreUpdatedSinceGenerate

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'))

  const hasSeenMyEstatePlanPage = _get(
    toggles,
    CONSTANTS.toggleTypes.HAS_SEEN_MY_ESTATE_PLAN_PAGE,
  )

  useEffect(() => amplitude.sendEvent('ViewedMyEstatePlanPage'), [])

  useEffect(() => {
    if (toggleFetchSuccess) {
      event.send({
        name: CONSTANTS.eventNames.MY_ESTATE_PLAN_PAGE,
        action: CONSTANTS.eventActionTypes.VIEWED,
        metadata: {
          userId,
          viewType: hasSeenMyEstatePlanPage ? 'return' : 'initial',
        },
      })

      if (!hasSeenMyEstatePlanPage) {
        handleUpdateToggles({
          token,
          questionnaireId,
          toggleOn: [CONSTANTS.toggleTypes.HAS_SEEN_MY_ESTATE_PLAN_PAGE],
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleFetchSuccess])

  return (
    <Box
      sx={{
        /*
         * Max of 3 cards (270px width each) + 2rem ('spacing' from DocumentsCards.tsx
         * Grid container) of padding on each side of the middle card
         */
        maxWidth: `calc((3 * 270px) + (2rem * 2))`,
        mx: 'auto',
      }}
    >
      {fetchDocumentsWorking || !toggles ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box>
            {/* HEADING */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mb: isMobile ? '3.4rem' : '4rem',
              }}
            >
              <Heading variant="h2" align="center" sx={{ mb: '0.6rem' }}>
                {answerStore.firstName
                  ? t('documents:label.case1', {
                      firstName: answerStore.firstName,
                    })
                  : t('documents:label.case2')}
              </Heading>

              <Text align="center" sx={{ mb: '1.2rem' }} variant="paragraph">
                {t('documents:subLabel')}
              </Text>

              {!hideEverythingButton && (
                <Box sx={{ mb: '1.1rem' }}>
                  <EverythingButton />
                </Box>
              )}

              <Grid container spacing={2} justifyContent="center">
                {hasPaidCoreDocument &&
                  answerStore.email &&
                  answerStore.firstName &&
                  answerStore.lastName &&
                  answerStore.province && (
                    <Grid item>
                      <EmailDocumentsButton
                        userId={userId}
                        answerStore={answerStore}
                        dispatchSetGlobalErrorMessage={
                          dispatchSetGlobalErrorMessage
                        }
                      />
                    </Grid>
                  )}

                {hasPaidCoreDocument &&
                  answerStore.firstName &&
                  answerStore.lastName &&
                  answerStore.province && (
                    <Grid item>
                      <PrintDocumentsButton
                        payments={payments}
                        answerStore={answerStore}
                        hasPrintCredit={hasPrintCredit}
                        generatedDocuments={generatedDocuments}
                        dispatchFetchAddOns={dispatchFetchAddOns}
                        dispatchSetGlobalErrorMessage={
                          dispatchSetGlobalErrorMessage
                        }
                        autoOpen={
                          autoOpenModalType ===
                          CONSTANTS.autoOpenModalTypes.PRINT_CREDIT
                        }
                      />
                    </Grid>
                  )}

                {witnessingProvinces.includes(answerStore.province) &&
                  hasPaidCoreDocument && (
                    <Grid item>
                      <WitnessingButton
                        hasPrintCredit={hasPrintCredit}
                        hasWitnessingCredit={hasWitnessingCredit}
                        autoOpen={
                          autoOpenModalType ===
                          CONSTANTS.autoOpenModalTypes.WITNESSING_CREDIT
                        }
                      />
                    </Grid>
                  )}
              </Grid>
            </Box>

            {React.Children.toArray(
              sectionsWithTriggeredCards.map(
                (
                  { topBanner, label: sectionLabel, cards: sectionCards },
                  i,
                ) => {
                  let cardsAbove = 0
                  for (let index = 0; index < i; index += 1) {
                    cardsAbove += sectionsWithTriggeredCards[index].cards.length
                  }

                  const delay = 0.2 + cardsAbove * ANIMATION_STAGGER

                  return (
                    <Box
                      initial="hidden"
                      animate="visible"
                      component={motion.div}
                      data-testid="document-section-container"
                      sx={{
                        mx: 'auto',
                        mb: isMobile ? '0' : '4rem',
                      }}
                      variants={containerAnimationVariants(delay)}
                    >
                      {topBanner && <Box sx={{ mb: '3rem' }}>{topBanner}</Box>}

                      {isMobile ? (
                        <Accordion
                          defaultExpanded
                          expandIconColorData={{
                            color: themeColorTypes.BRAND,
                            colorVariant: themeColorVariants.LIGHT,
                          }}
                          dividerColorData={{
                            color: themeColorTypes.GREY,
                            colorVariant: themeColorVariants.LIGHTER,
                          }}
                          headingData={{
                            variant: 'h3',
                            label: sectionLabel,
                            color: themeColorTypes.BRAND,
                            colorVariant: themeColorVariants.MAIN,
                          }}
                          onClick={(isOpen) => {
                            amplitude.sendEvent(
                              `${
                                isOpen ? 'Opened' : 'Closed'
                              }DocumentsMobileAccordion`,
                              {
                                sectionLabel,
                              },
                            )
                          }}
                        >
                          <Box sx={{ mt: '0.1rem' }}>
                            <DocumentCards
                              cards={sectionCards}
                              triggerObj={triggerObj}
                              answerStore={answerStore}
                              generatedDocuments={generatedDocuments}
                              dispatchSetGlobalErrorMessage={
                                dispatchSetGlobalErrorMessage
                              }
                            />
                          </Box>
                        </Accordion>
                      ) : (
                        <>
                          <Heading variant="h3" sx={{ mb: '1.7rem' }}>
                            {sectionLabel}
                          </Heading>

                          <DocumentCards
                            cards={sectionCards}
                            triggerObj={triggerObj}
                            answerStore={answerStore}
                            generatedDocuments={generatedDocuments}
                            dispatchSetGlobalErrorMessage={
                              dispatchSetGlobalErrorMessage
                            }
                          />
                        </>
                      )}
                    </Box>
                  )
                },
              ),
            )}
          </Box>

          <DocumentsGuideModal
            token={token}
            userId={userId}
            show={showSteppedModal}
            answerStore={answerStore}
            hasPrintCredit={hasPrintCredit}
            questionnaireId={questionnaireId}
            dispatchSetGlobalErrorMessage={dispatchSetGlobalErrorMessage}
            onClose={() => {
              /*
               * We set this here to prevent the follow-up signing modal
               * from appearing immediately after closing the stepped modal
               */
              sessionStore.set(
                CONSTANTS.sessionStorageKeys.PREVENT_SIGNING_MODALS,
                true,
              )
              setForceHideSteppedDocumentsModal(true)
            }}
          />

          <StaleDocumentsModal
            show={showStaleDocumentsModal}
            questionnaireValid={questionnaireValid}
            generateDocumentsWorking={generateDocumentsWorking}
            onClose={() => setForceHideStaleDocumentsModal(true)}
          />

          <SignReminderModal
            token={token}
            show={showSignReminderModal}
            questionnaireId={questionnaireId}
            onClose={() => {
              sessionStore.set(
                CONSTANTS.sessionStorageKeys.PREVENT_SIGNING_MODALS,
                true,
              )
              setForceHideSignReminderModal(true)
            }}
          />

          <FollowUpSigningModal
            token={token}
            show={showFollowUpSigningModal}
            questionnaireId={questionnaireId}
            onClose={() => {
              sessionStore.set(
                CONSTANTS.sessionStorageKeys.PREVENT_SIGNING_MODALS,
                true,
              )
              setForceHideFollowUpSigningModal(true)
            }}
          />
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: any) => ({
  userId: dashboardSelectors.getUserId(state.dashboard),
  payments: paymentsSelectors.getPayments(state.dashboard),
  paidAddOns: addOnsSelectors.getPaidAddOns(state.dashboard),
  token: questionnaireSelectors.getToken(state.questionnaire),
  documents: documentsSelectors.getDocuments(state.dashboard),
  referrer: questionnaireSelectors.getReferrer(state.questionnaire),
  answerStore: questionnaireSelectors.getAnswerStore(state.questionnaire),
  noticeConnectCode: dashboardSelectors.getNoticeConnectCode(state.dashboard),
  generateDocumentsWorking: documentsSelectors.getGenerateDocumentsWorking(
    state.dashboard,
  ),
  questionnaireId: questionnaireSelectors.getQuestionnaireId(
    state.questionnaire,
  ),
  fetchDocumentsWorking: documentsSelectors.getFetchDocumentsWorking(
    state.dashboard,
  ),
  questionnaireValid: questionnaireSelectors.getQuestionnaireValid(
    state.questionnaire,
  ),
  answerStoreUpdatedSinceGenerate:
    questionnaireSelectors.getAnswerStoreUpdatedSinceGenerate(
      state.questionnaire,
    ),
  bonusDocsAnswerStore: bonusDocsSelectors.getBonusDocsAnswerStore(
    state.dashboard,
  ),
  customerReferralCode: dashboardSelectors.getCustomerReferralCode(
    state.dashboard,
  ),
})

const mapDispatchToProps = (dispatch: any) => ({
  dispatchFetchAddOns: () =>
    dispatch(addOnsActions.fetchAddOns({ clearAddOnCart: false })),
  dispatchSetGlobalErrorMessage: (msg: string) =>
    dispatch(commonActions.setGlobalErrorMessage(msg)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
